document.addEventListener("DOMContentLoaded", () => {
  class UIApp {
    constructor() {
      this.cacheDOMElements();
      this.checkBrowserCompatibility();
      this.initializeEventListeners();
    }

    cacheDOMElements() {
      this.toTopButton = document.querySelector(".js-to-top");
      this.categoryToggleButton = document.querySelector(".js-toggler-cat");
      this.searchToggleButton = document.querySelector(".js-toggler-search");
      this.categoryMenu = document.querySelector(".js-categories");
      this.hamburgerMenu = document.querySelector(".js-hamburger");
      this.searchBar = document.querySelector(".js-search");
    }

    checkBrowserCompatibility() {
      if (!window.addEventListener) {
        document.body.hidden = true;
        alert("Please update your browser to continue using this site. Thank you!");
      }
    }

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    toggleCategoryMenu() {
      this.hamburgerMenu.classList.toggle('active');
      this.categoryMenu.toggleAttribute("hidden");
    }

    toggleSearchBar() {
      this.searchBar.toggleAttribute("hidden");
    }

    initializeEventListeners() {
      if (this.toTopButton) {
        this.toTopButton.addEventListener("click", this.scrollToTop.bind(this));
      }

      if (this.categoryToggleButton) {
        this.categoryToggleButton.addEventListener("click", this.toggleCategoryMenu.bind(this));
      }

      if (this.searchToggleButton) {
        this.searchToggleButton.addEventListener("click", this.toggleSearchBar.bind(this));
      }

      window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    handleScroll() {
      if (this.toTopButton) {
        this.toTopButton.style.display = window.scrollY > 40 ? "block" : "none";
      }
    }
  }

  new UIApp();
});
